var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-modal',{attrs:{"id":"my-modal","title":"Numeraciones","size":"md","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"400px","margin-left":"0 auto !important","margin-right":"0 auto !important"},attrs:{"src":_vm.logoEmpresa,"alt":""}})])]),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_c('b',[_vm._v("NUMERACIONES")])])]},proxy:true}])},[_c('div',{staticClass:"text-right px-4 mt-3"},[_c('button',{staticClass:"mx-1 btn btn-success",on:{"click":function($event){return _vm.$router.push('Numeracion_agregar')}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v("Agregar Numeración ")])]),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_numeraciones"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Tipo de documento")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Preferida")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Prefijo")]),_c('th',[_vm._v("Opciones")])])]),_c('tbody',_vm._l((_vm.listaItems),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.tipo_documento == 0 ? "Cancelación de ajuste" : item.tipo_documento == 1 ? "Nómina electrónica" : "Cancelación de Nómina")+" ")]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[_c('div',{staticClass:"form-check form-switch"},[_c('label',{staticClass:"form-check-label",attrs:{"for":'aflexSwitchCheckDefault'}}),_c('VueToggles',{attrs:{"height":"20","width":"50","value":item.preferida == 0 ? false : true},on:{"click":function($event){_vm.preferredUpdate(item, item.preferida, !$event);
                        item.preferida == 0
                          ? (item.preferida = 1)
                          : (item.preferida = 0);}}})],1)]),_c('td',[_c('div',{staticClass:"form-check form-switch"},[_c('label',{staticClass:"form-check-label",attrs:{"for":'aflexSwitchCheckDefault'}}),_c('VueToggles',{attrs:{"height":"20","width":"50","value":item.estado == 0 ? false : true},on:{"click":function($event){item.estado == 0
                          ? (item.estado = 1)
                          : (item.estado = 0);
                        _vm.updateState(item, item.estado);}}})],1)]),_c('td',[_vm._v(_vm._s(item.prefijo))]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center px-0 mx-0"},[_c('vs-button',{attrs:{"success":"","icon":""},on:{"click":function($event){return _vm.$router.push('numeracion_editar/' + item.id)}}},[_c('i',{staticClass:"fas fa-solid fa-pen"})]),_c('vs-button',{attrs:{"icon":"","danger":""},on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fas fa-solid fa-trash"})])],1)])])}),0)])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }