<template>
  <b-container fluid>
    <b-modal id="my-modal" title="Numeraciones" size="md" hide-footer>
      <div class="text-center">
        <img
          class="img-fluid"
          style="
            max-width: 400px;
            margin-left: 0 auto !important;
            margin-right: 0 auto !important;
          "
          :src="logoEmpresa"
          alt=""
        />
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>NUMERACIONES</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <button
              class="mx-1 btn btn-success"
              @click="$router.push('Numeracion_agregar')"
            >
              <i class="fas fa-plus"></i>Agregar Numeración
            </button>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_numeraciones"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Tipo de documento</th>
                  <th>Nombre</th>
                  <th>Preferida</th>
                  <!-- <th>Imagen</th> -->
                  <th>Estado</th>
                  <th>Prefijo</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{
                      item.tipo_documento == 0
                        ? "Cancelación de ajuste"
                        : item.tipo_documento == 1
                        ? "Nómina electrónica"
                        : "Cancelación de Nómina"
                    }}
                  </td>

                  <td>{{ item.nombre }}</td>
                  <td>
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        :for="'aflexSwitchCheckDefault'"
                      >
                      </label>
                      <VueToggles
                        height="20"
                        width="50"
                        :value="item.preferida == 0 ? false : true"
                        @click="
                          preferredUpdate(item, item.preferida, !$event);
                          item.preferida == 0
                            ? (item.preferida = 1)
                            : (item.preferida = 0);
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <!-- {{ item.estado == null || item.estado != 1 ? 'Inactiva' : 'Activa' }} -->

                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        :for="'aflexSwitchCheckDefault'"
                      >
                      </label>
                      <VueToggles
                        height="20"
                        width="50"
                        :value="item.estado == 0 ? false : true"
                        @click="
                          item.estado == 0
                            ? (item.estado = 1)
                            : (item.estado = 0);
                          updateState(item, item.estado);
                        "
                      />
                    </div>
                  </td>
                  <td>{{ item.prefijo }}</td>
                  <!-- <td>
                     <img
                       @click="
                         logoEmpresa = item.empresa.urlLogo;
                         $bvModal.show('my-modal');
                       "
                       class="avatar-35"
                       :src="item.empresa.urlLogo"
                       alt=""
                     />
                   </td> -->

                  <td>
                    <div class="d-flex justify-content-center px-0 mx-0">
                      <vs-button
                        @click="$router.push('numeracion_editar/' + item.id)"
                        success
                        icon
                      >
                        <i class="fas fa-solid fa-pen"></i>
                      </vs-button>
                      <vs-button @click="deleteRow(item)" icon danger>
                        <i class="fas fa-solid fa-trash"></i>
                      </vs-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [{}],
      logoEmpresa: "",
      usuario: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_numeraciones").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async preferredUpdate(item, value, e) {
      try {
        console.log(item);
        console.log(value);
        console.log(e);
        for await (let it of this.listaItems) {
          console.log(it.id);
          console.log(item.id);
          if (it.id == item.id) {
            console.log("igual");
            it.Preferida = e;
            delete it.preferida;
          } else {
            console.log("no igual");
            it.Preferida = false;
          }
          await this.$store.dispatch("hl_post", {
            path: "Numeracion/Numeracion/",
            data: it,
          });
        }
        await this.getData();
      } catch (error) {
        console.log("error---", error);
      }
    },
    async updateState(item, e) {
      try {
        item.Estado = e;
        console.log(item);
        await this.$store.dispatch("hl_post", {
          path: "Numeracion/Numeracion/",
          data: item,
        });
        await this.getData();
      } catch (error) {
        console.log("error---", error);
      }
    },
    async deleteRow(item) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          item.Eliminado = 1;
          await this.$store.dispatch("hl_delete", {
            path: "Numeracion/EliminarNumeracion/" + item.id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log(".....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "Numeracion/GetNumeraciones/" + this.usuario.empresa.id,
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
